import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"

import Layout from "../layout/Layout"
import Seo from "../components/Seo"

import Card from "../components/Card"
import CardImage from "../components/CardImage"
import { navigate } from "gatsby"
import respond from "../styles/abstracts/mediaqueries"

const StyledFailure = styled.main`
  h1 {
    color: darkred;
  }

  .card-container {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    gap: 6rem;
    width: 70%;
    margin: 0 auto;

    ${respond(
      "ipad-pro-12-port",
      css`
        width: 90%;
      `
    )}
    ${respond(
      "ipad-pro-11-port",
      css`
        width: 100%;
      `
    )}
    ${respond(
      "iphone-12-pro-land",
      css`
        grid-template-columns: 1fr;
        grid-template-rows: repeat(max-content, 2);
        gap: 2rem;
      `
    )}
  }

  p {
    width: 100%;

    ${respond(
      "iphone-12-pro-land",
      css`
        grid-row: 2 / 3;
      `
    )}
  }

  .image {
    ${respond(
      "iphone-12-pro-land",
      css`
        grid-row: 1 / 2;
        width: 70%;
        margin: 0 auto;
      `
    )}
  }
`

const FailurePage = props => {
  const isBrowser = typeof window !== "undefined"
  const first_name = props?.location?.state?.formData?.first_name
  if (!first_name && isBrowser) {
    navigate("/")
    return ""
  }
  return (
    <Layout innerLayout>
      <Seo title="Giovanni Setti | Purtroppo Per Ora Non Qualifichi" />
      <StyledFailure className="container">
        <Card>
          <h1>PURTROPPO PER ORA NON QUALIFICHI</h1>
          <div className="card-container">
            <div className="text-container">
              <p>
                Grazie {first_name || ""} per aver inviato la tua candidatura.
              </p>
              <p>
                Purtroppo per ora non hai raggiunto i requisiti necessari per
                accedere alla consulenza gratuita di Giovanni Setti.
              </p>
              <p>Credi ci sia stato un errore?</p>
              <p>
                Scrivici pure a{" "}
                <a href="mailto:info@giovannisetticonsulting.com">
                  info@giovannisetticonsulting.com
                </a>{" "}
                e analizziamo insieme la tua situazione!
              </p>
            </div>
            <CardImage className="image">
              <StaticImage src="../images/giovanni.png" alt="Giovanni Setti" />
            </CardImage>
          </div>
        </Card>
      </StyledFailure>
    </Layout>
  )
}

export default FailurePage
